import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/styles';

import { MyMenu as Menu } from './MyMenu';
import { MyAppBar as AppBar } from './MyAppBar';
import { MySidebar as Sidebar } from './MySidebar';
import { MyNotification as Notification } from './MyNotification';

//import { Layout } from 'react-admin';
import {
    //Menu, // as MyMenu
    //AppBar, // as MyAppBar
    //Sidebar, // as MySidebar
    //Notification, // as MyNotification
    setSidebarVisibility,
    //ComponentPropType,
} from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        //overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: theme.spacing(3),
        marginTop: '4em',
        //paddingLeft: 5,
    },
}));

export const MyLayout = ({
    children,
    dashboard,
    logout,
    title,
    //...props
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
    const open = useSelector(state => state.admin.ui.sidebarOpen) || isDesktop;

    // see https://stackoverflow.com/a/58624276
    const stableDispatch = useCallback(dispatch, []);

    // see https://stackoverflow.com/questions/56874431/how-to-start-with-left-menu-collapsed/56881653
    useEffect(() => {
        //dispatch(setSidebarVisibility(false));
        stableDispatch(setSidebarVisibility( !!isDesktop ));
    }, [ stableDispatch, isDesktop ]);
    

    return (
        //true ? ( // fully customized layout
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar title={title} open={open} logout={logout} />
                    <main className={classes.contentWithSidebar}>
                        <Sidebar>
                            <Menu logout={logout} hasDashboard={!!dashboard} />
                        </Sidebar>
                        <div className={classes.content}>
                            {children}
                        </div>
                    </main>
                    <Notification />
                </div>
            </div>
        //):( // default layout
        //    <Layout
        //        {...props}
        //        appBar={AppBar}
        //        sidebar={Sidebar}
        //        menu={Menu}
        //        notification={Notification}
        //    />
        //)
    );
}

MyLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    //logout: ComponentPropType,
    title: PropTypes.string.isRequired,
};

export default MyLayout;