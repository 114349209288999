import * as React from "react";
import { Route } from 'react-router-dom';
import * as async from 'lib/async';

const Forgot = async.Page('Forgot'); //import Forgot from 'views/Forgot';
const Reset = async.Page('Reset'); //import Reset from 'views/Reset';
const Register = async.Page('Register'); //import Register from 'views/Register';
const Payment = async.Page('Payment'); //import Register from 'views/Register';

export default [
    <Route exact path="/forgot" component={Forgot} noLayout />,
    <Route exact path="/reset" component={Reset} noLayout />,
    <Route exact path="/register" component={Register} noLayout />,

    // dirty hack is dirty, for backwards compatibility
    <Route exact path="/client/forgot" component={Reset} noLayout />,

    // for payments
    <Route exact path="/payment" component={Payment} />,
];
