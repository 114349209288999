import React from "react";
import { AppBar } from 'react-admin';
//import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { MyUserMenu } from './MyUserMenu'

import { MyLogo as Logo } from './MyLogo';

export const useStyles = makeStyles({ 
    root: {
        background:'#000'
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    inner: {
        width:'100%',
        maxWidth:980,
        margin:"0 auto"
    },
    spacer: {
        flex: 1,
    },
});

export const MyAppBar = props => {
    const classes = useStyles();
    return (
		<AppBar 
			userMenu={<MyUserMenu/>}
            {...props}
            className={classes.root}
		>

            <div className={classes.inner}>
                
                <Logo/>
                
                {/* 
                // https://marmelab.com/react-admin/Theming.html#customizing-the-appbar-content
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
                */}

                <span className={classes.spacer} />

            </div>
        </AppBar>
    );
};