import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

export const useSidebarStyles = makeStyles(theme => ({
    drawerPaper: {
        //backgroundColor: 'red'
        [theme.breakpoints.up('sm')]: {
            marginTop: '5.5em',
        }
    },
}));

export const MySidebar = props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};
