import React, { forwardRef } from "react";
import { 
	//useTranslate, 
	UserMenu
	//, MenuItemLink
} from 'react-admin';
//import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});

const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
            //src="https://marmelab.com/images/avatars/adrien.jpg"
        />
    )
};

export const SettingsMenu = forwardRef(({ onClick }, ref) => {
	//const translate = useTranslate();
	return null;
	/*
	return (
		<MenuItemLink
			ref={ref}
			to="/settings"
			primaryText={translate("ra.menu.settings",{"_":"Configuration"})}
			leftIcon={<SettingsIcon />}
			onClick={onClick} // close the menu on click
		/>
	);
	*/
});

export const MyUserMenu = props => (
	<UserMenu
		icon={<MyCustomIcon/>}
		{...props}
	>
        <SettingsMenu />
    </UserMenu>
);