import React, { useEffect } from 'react';
import Config from 'lib/config'

import './App.scss';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

// Import the CSS
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import { 
    Admin, 
    Resource,
    //ListGuesser, 
    //ShowGuesser,
    resolveBrowserLocale,
} from 'react-admin';

import 'lib/security';
import authProvider from 'lib/authProvider';
import dataProvider from 'lib/dataProvider';
import i18nProvider from 'lib/i18nProvider';
import * as async from 'lib/async';

//import InvoiceIcon from '@material-ui/icons/Receipt';
//import JobIcon from '@material-ui/icons/LocalShipping';
//import EnrolmentIcon from '@material-ui/icons/CardMembership';
//import AssessmentIcon from '@material-ui/icons/AssignmentTurnedIn';
//import ComplianceIcon from '@material-ui/icons/EventAvailable';
//import ProfileIcon from '@material-ui/icons/Person';
//import PagesIcon from '@material-ui/icons/School';
//import ProgramIcon from '@material-ui/icons/CheckCircle';
import HandbookIcon from '@material-ui/icons/MenuBook';
import GuideIcon from '@material-ui/icons/LibraryBooks';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import TestIcon from '@material-ui/icons/Speed';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');


import 'lib/install';

import { MyTheme } from './theme/MyTheme';
import { MyLayout } from './theme/MyLayout';
import { MyLogoutButton } from './theme/MyLogout';

//import history from './lib/history';
// doesn't work in multi-tenant cross-domain apps

import customRoutes from './routes';
import customReducers from './reducers';

const Login = async.Page('Login');
//const Profile = async.Resource('Profile');
const Dashboard = async.Page('Dashboard');
const Session = async.Resource('Session');
//const Enrolment = async.Resource('Enrolment');
//const Compliance = async.Resource('Compliance');
//const Assessment = async.Resource('Assessment');
const Handbook = async.Resource('Handbook');
const Guide = async.Resource('Guide');
const Video = async.Resource('Video');
const Test = async.Resource('Test');
//const Pages = async.Resource('Pages');

export const App = () => {
    console.log("App()");

    //http://localhost:3000/client/forgot?crypto=26577.c8a9d36bd35903732a6a407ae05cce17e0e7246a820ef33d1cfcda618fa17b6e@20200802132821.90371870592604c9cbbaf49692810aaa0176d12dd467d0fa402a357be1580dbd&r=/checkout#/login
    const { hash, pathname, search } = window.location;
    const forgot = "/client/forgot";
    useEffect(()=>{
        console.log("start", {hash, forgot, pathname, search});
        if(pathname===forgot){
            console.log("start push", {hash, forgot, pathname, search});
            console.log("start redirect to", {location:forgot+search})
            //window.location.hash = forgot+search
            window.location = "/#" + forgot+search
        }
    }, [ hash, search, pathname ]);
    console.log("start go", {hash, forgot, pathname, search});
    if(pathname===forgot && hash!==`#${pathname}${search}`) return '';

    return (
        <ThemeProvider theme={MyTheme}>
            <CssBaseline />
            <Admin
                
                // history={history} 
                // doesn't work in multi-tenant cross-domain apps

                theme={MyTheme} 
                layout={MyLayout}
                logout={MyLogoutButton}
                loginPage={Login}
                dashboard={Dashboard} 
                initialState={({})} 
                authProvider={authProvider(Config)} 
                dataProvider={dataProvider(Config)}
                i18nProvider={i18nProvider}
                locale={resolveBrowserLocale()}
                customRoutes={customRoutes}
                customReducers={customReducers}
            >
                    
                <Resource name="profile"/>
                <Resource name="thisweek"/>
                <Resource name="enrolment"/>
                <Resource name="program"/>
                <Resource name="profile"/>
                <Resource name="cycle"/>
                <Resource name="week"/>
                <Resource name="session" 
                    // options={{label: "Session"}} 
                    // icon={Session} 
                    show={Session.Show} 
                    // list={Session.List} 
                    sort={{ field: 'date', order: 'DESC' }}
                />

                {/*
                <Resource name="enrolment-assessment" 
                    options={{label: "Assessments"}} 
                    icon={AssessmentIcon} 
                    show={Assessment.Show} 
                    list={Assessment.List} 
                    sort={{ field: 'date', order: 'DESC' }}
                />
                    
                <Resource name="enrolment" 
                    options={{label: "Enrolments"}} 
                    icon={EnrolmentIcon} 
                    show={Enrolment.Show} 
                    list={Enrolment.List} 
                    sort={{ field: 'date', order: 'DESC' }}
                />
                
                <Resource name="profile" 
                    options={{label: "Profile"}} 
                    icon={ProfileIcon} 
                    show={Profile.Show} 
                    list={Profile.Show} 
                    edit={Profile.Edit} 
                    //sort={{ field: 'date', order: 'DESC' }}
                />
                */}
    
                {/* <Resource name="compliance"/> */}
                {/* <Resource name="program"/> */}
                {/* <Resource name="program-cycle"/> */}
                {/* <Resource name="program-cycle-week"/> */}
                {/* <Resource name="program-cycle-week-session"/> */}
                {/* <Resource name="program-cycle-week-session-exercise"/> */}
                
                <Resource name="video" 
                    options={{label: "Videos"}} 
                    icon={VideoIcon} 
                    show={Video.Show} 
                    list={Video.List} 
                    edit={Video.Edit} 
                    //sort={{ field: 'date', order: 'DESC' }}
                />
                
                <Resource name="handbook" 
                    options={{label: "Handbooks"}} 
                    icon={HandbookIcon} 
                    show={Handbook.Show} 
                    list={Handbook.List} 
                    edit={Handbook.Edit} 
                    //sort={{ field: 'date', order: 'DESC' }}
                />
    
                <Resource name="guide" 
                    options={{label: "Guides"}} 
                    icon={GuideIcon} 
                    show={Guide.Show}
                    list={Guide.List}
                    edit={Guide.Edit}
                    //sort={{ field: 'date', order: 'DESC' }}
                />
                
                <Resource name="test.type"/>
                <Resource name="test.folder"/>
                <Resource name="test.comment"/>
                <Resource name="test" 
                    options={{label: "Tests"}} 
                    icon={TestIcon} 
                    show={Test.Show} 
                    list={Test.List} 
                    create={Test.Create} 
                    sort={{ field: 'date', order: 'DESC' }}
                />
                
                {/* 
                <Resource name="transaction"
                    list={TransactionList}
                    show={TransactionShow}
                    icon={TransactionIcon}
                />
                */}
    
                {/*
                <Resource name="pages" 
                    options={{label: "Pages"}} 
                    icon={PagesIcon} 
                    show={Pages.Show} 
                    list={Pages.List} 
                    edit={Pages.Edit} 
                    //sort={{ field: 'date', order: 'DESC' }}
                />
                */}
    
                {/* <Resource name="pages"     icon={PostIcon} list={PageList}    edit={PageEdit}    create={PageCreate} /> */}
                {/* <Resource name="products"  icon={PostIcon} list={ListGuesser} edit={EditGuesser} create={PageCreate} /> */}
                {/* <Resource name="drivers"   icon={PostIcon} list={ListGuesser} edit={EditGuesser} create={PageCreate} /> */}
                {/* <Resource name="users"     icon={UserIcon} list={ListGuesser} edit={EditGuesser} /> */}
            </Admin>
        </ThemeProvider>
    );
}

export default App;