export default {
    ra:{
        auth:{
            password: "Password",
            username: "Username",

            login_button:"Login",
            login_invalid:"Invalid username or password",
            login_forgot_link: "Forgot Password?",
            login_title: "Please Login",
            login_remember: "Remember login?",
            login_register_link: "Register now",
            login_success:"Login success",        
            login_error:"Login error",        

            register_button: "Register Now",
            register_invalid: "Invalid information",
            register_title: "Register Now",
            register_login_link: "Already have an account? Login here",
            register_success: "Registration confirmed!",
            register_error: "Registration error",

            reset_button: "Reset password",
            reset_invalid: "Invalid information",
            reset_title: "Reset Password",
            reset_success: "Password reset!",
            reset_error: "Could not reset password",
		},
		sp: {
			session_complete: "Session complete"
		},
        error: {
            password_mismatch: "Passwords did not match",
            name_required: "Name required",
            email_required: "Email required",
            mobile_required: "Mobile required",
            program_required: "Program required",
        }
    }
}