import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/styles';

//const src = "https://res.cloudinary.com/sampepys/image/upload/w_180,h_180/logo/logo-square-black_sadt55.png";
const src = "https://res.cloudinary.com/sampepys/image/upload/v1608136049/logo/aei-square-640.png";
const height = 48;

const useStyles = makeStyles(theme => ({
    root: {
    	position: "absolute",
    	left: "calc(50vw - 30px)",
    	width: "60px",
    	height: "60px",
    	top: "5px",
    },
}));

export const MyLogo = ({
    title,
    //...props
}) => {
    const classes = useStyles();

    return (
		<div className={classes.root} title={title}>
			<img src={src}
				height={height}
				//width={width}
				alt={title || ""}
			/>
		</div>
    );
}

export default MyLogo;