import Loadable from 'react-loadable';
import Loading from 'theme/MyLoading';

export const Page = (name, method) => Loadable({
    loader: () => import('views/pages/'+name).then(m=>m[name+(method||'')]),
    loading: Loading,
    delay: 300, // 0.3 seconds
});

export const ResourceAction = (name, method) => Loadable({
    loader: () => import('views/resources/'+name).then(m=>m[name+(method||'')]),
    loading: Loading,
    delay: 300, // 0.3 seconds
});

// async.Resource
export const Resource = ( name ) => {
	return {
		Edit: ResourceAction( name, 'Edit' ),
		Create: ResourceAction( name, 'Create' ),
		Show: ResourceAction( name, 'Show' ),
		List: ResourceAction( name, 'List' ),
		Empty: ResourceAction( name, 'Empty' ),
	}
}
