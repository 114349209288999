import React from 'react';
//import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
//import ContactIcon from '@material-ui/icons/EmailOutlined';
//import LabelIcon from '@material-ui/icons/Label';
import ProgramIcon from '@material-ui/icons/CheckCircle';

export const MyMenu = ({ onMenuClick, logout }) => {
	const translate = useTranslate();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
    const open = useSelector(state => state.admin.ui.sidebarOpen) || isDesktop;
    const resources = useSelector(getResources);
    console.log('MyMenu',{isXSmall,isDesktop,open,resources});
    return (
        <div>
			<MenuItemLink
				to="/"
				primaryText={translate("ra.menu.dashboard", { _: 'Program' })}
				leftIcon={<ProgramIcon />}
				onClick={onMenuClick}
				sidebarIsOpen={open}
			/>
            {resources.map(resource => (
				!!(resource.options && resource.options.label) ? (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                /> ) : null
            ))}

            {/*
            <MenuItemLink
                to="/contact"
                primaryText="Contact"
                leftIcon={<ContactIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            */}
            {isXSmall && logout}
        </div>
    );
};