// identification and encryption
import { fingerprint, cid } from './security';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import Token from './token';

/*
Action	Expected API request
Get list	GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]&filter={title:'bar'}
Get one record	GET http://my.api.url/posts/123
Get several records	GET http://my.api.url/posts?filter={id:[123,456,789]}
Get related records	GET http://my.api.url/posts?filter={author_id:345}
Create a record	POST http://my.api.url/posts/123
Update a record	PUT http://my.api.url/posts/123
Update records	PUT http://my.api.url/posts?filter={id:[123,124,125]}
Delete a record	DELETE http://my.api.url/posts/123
Delete records	DELETE http://my.api.url/posts?filter={id:[123,124,125]}
*/

export default (Config) => {
    const { apikey } = Config;

    const apiUrl = (Config.API_BASE || 'https://'+Config.domain+(Config.path || '/x/api'))+'/dash';
    const httpClient = (url, options = {}) => {
        console.log('httpClient',{url,options});

        const token = Token.string() //localStorage.getItem('token');
        
        if (!options.headers) {
            options.headers = new Headers({
                "Accept": "application/json",
                apikey, fingerprint, cid, token
            });
        }
        
        // send cookies
        options.credentials = 'include';

        // if token is expected as header, eg.: bearer token
        //options.headers.set('Authorization', `Bearer ${token}`);

        console.log('httpClient fetchUtils.fetchJson',{url,options});
        return fetchUtils.fetchJson(url, options)
            .then(res=>{
                console.log('httpClient fetchUtils.fetchJson res',{res,url,options});
                return res;
            })
        ;
    };

    return  {

        //authenticate: () => {
        //    
        //}

        getList: (resource, params) => {
            console.log('getList', {resource, params});
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                _sort: field, _order: order, //sort: JSON.stringify([field, order]),
                _page: page, _recpp: perPage, //range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                ...params.filter, //filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;


            return httpClient(url).then(({ headers, json }) => {
                console.log('getList', {resource, params, headers, json});
                return ({
                    data: json && json.data,
                    total: parseInt(headers.get('x-total-count')), //.split('/').pop(), 10),
                })
            });
        },

        getOne: (resource, params) => {
            console.log('getOne', {resource, params});
            return httpClient(`${apiUrl}/${resource}${params.id && '/'}${params.id}`).then(({ json }) => ({
                data: json && json.data,
            }))
        },

        getMany: (resource, params) => {
            console.log('getMany', {resource, params});
            const query = {
                id: params.ids //filter: JSON.stringify({ id: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            return httpClient(url).then(({ json }) => ({ data: json.data }));
        },

        getManyReference: (resource, params) => {
            console.log('getManyReference', {resource, params});
            const { page, perPage } = params.pagination || {};
            const { field, order } = params.sort || {};
            const query = {
                _sort: field, _order: order, //sort: JSON.stringify([field, order]),
                _page: page, _recpp: perPage, //range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                ...({ ...params.filter, [params.target]: params.id, }), //filter: JSON.stringify({ ...params.filter, [params.target]: params.id, }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;

            return httpClient(url).then(({ headers, json }) => ({
                data: json && json.data,
                total: parseInt(headers.get('x-total-count')), //.split('/').pop(), 10),
            }));
        },

        update: (resource, params) => {
            console.log('update', {resource, params});
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json && json.data }))
        },

        post: (resource, data) => {
            console.log('post', {resource, data});
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(data),
            }).then(({ json }) => ({ data: json && json.data }))
        },

        updateMany: (resource, params) => {
            console.log('updateMany', {resource, params});
            const query = {
                filter: JSON.stringify({ id: params.ids}),
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json && json.data }));
        },

        create: (resource, params) => {
            console.log('updateMany', {resource, params});
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json && json.data.id },
            }));
        },

        delete: (resource, params) => {
            console.log('delete', {resource, params});
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'DELETE',
            }).then(({ json }) => ({ data: json && json.data }));
        },

        deleteMany: (resource, params) => {
            console.log('deleteMany', {resource, params});
            const query = {
                filter: JSON.stringify({ id: params.ids}),
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json && json.data }));
        }
    };
}