import { ParseContext, EnvironmentConstants } from '@fyne/ui/context';
const context = ParseContext(process.env);
const constants = EnvironmentConstants(process.env);

console.log("Config", {env:process.env,constants,context,parsed:ParseContext(process.env)});

export default {
    ...context
    //"client": "dashboard",
    //"apikey": "jwt?",
    //"domain": "domain.com"
}